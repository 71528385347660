import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook

function DevCentral() {
  const refTextract = useRef(null);
  const refGit = useRef(null);
  const refSources = useRef(null);

  const inViewTextract = useInView(refTextract);
  const inViewGit = useInView(refGit);
  const inViewSources = useInView(refSources);

  return (
    <Layout>
      <div className="pt-16 md:pt-20 bg-white">
        <div className="flex flex-col space-y-2 sm:space-y-10 mx-auto py-2 ">
          <div className="flex justify-center items-center pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 bg-white animate__animated animate__fadeIn">
            <div className="grid grid-cols-1 max-w-4xl items-stretch mx-auto gap-12">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                DevCentral
              </p>

              <div className="flex flex-col justify-center items-center gap-4">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  A Developer's Resource Library to help you optimize your
                  coding workflow, learn new skills, master the art of software
                  development, and stay ahead of the curve with the latest
                  tools, best practices, and expert insights.
                </p>
                <p className="text-md md:text-lg text-slate-600 text-center leading-normal xs:leading-normal md:leading-normal">
                  {" "}
                  Everything you need to Enhance your Coding Skills and
                  Development Process.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pb-24 pt-24 pl-8 pr-8 xs:pl-12 xs:pr-12 sm:pl-16 sm:pr-16 lg:pl-20 lg:pr-20 bg-gray-50">
            <div className="grid grid-cols-1 items-stretch max-w-6xl mx-auto pt-4">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-24 rounded-xl bg-gray-50">
                {/* Image Text Extractor  */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-xl bg-white hover:shadow-md h-git"
                  ref={refTextract}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2">
                      <a
                        href="https://devcentral.prabhavdev.me/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-lg lg:text-xl font-semibold"
                      >
                        <button className="hover:bg-black bg-white text-black hover:text-white font-semibold text-sm xs:text-md py-2 px-2 border border-gray-300 rounded-lg transition duration-300 ease-in-out">
                          Textract
                        </button>
                      </a>
                      <p className="text-md sm:text-lg hidden xs:block">
                        - git checkout{" "}
                        <a
                          href="https://devcentral.prabhavdev.me/"
                          target="_blank"
                          rel="noreferrer"
                          className="link_hover_effect"
                        >
                          <b>DevCentral</b>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div
                    className={`bg-gray-50 p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      inViewTextract ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      An advanced Image Text Extractor built using{" "}
                      <b>Tesseract.js</b>, enabling high-accuracy <b>OCR</b>{" "}
                      with multi-language support and structured text
                      preservation utilizing OCR in the background for efficient
                      processing. The tool features <b>PSM AUTO</b> for advanced
                      text segmentation, detecting paragraphs separately to
                      maintain the original layout.
                    </p>
                  </div>
                  <div
                    className={`bg-gray-50 p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      inViewTextract ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      One standout feature of this tool is its ability to
                      extract text from images containing multiple languages
                      simultaneously. If an image has text in English, French,
                      Spanish, Hindi, or any other combination, the tool can
                      recognize and extract all of them without requiring manual
                      language selection, using the <b>Mix</b> option. This
                      ensures accurate text retrieval from{" "}
                      <b>
                        multilingual documents, street signs, business cards,
                        and scanned books
                      </b>
                      , without any loss of information.
                    </p>
                  </div>
                </div>

                {/* Git commands */}

                <div
                  className="flex flex-col space-y-6 p-8 rounded-xl bg-white hover:shadow-md h-fit"
                  ref={refGit}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2">
                      <a
                        href="https://devcentral.prabhavdev.me/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-lg lg:text-xl font-semibold"
                      >
                        <button className="hover:bg-black bg-white text-black hover:text-white font-semibold text-sm xs:text-md py-2 px-2 border border-gray-300 rounded-lg transition duration-300 ease-in-out">
                          GitGuide
                        </button>
                      </a>
                      <p className="text-md sm:text-lg hidden xs:block">
                        - git checkout{" "}
                        <a
                          href="https://devcentral.prabhavdev.me/"
                          target="_blank"
                          rel="noreferrer"
                          className="link_hover_effect"
                        >
                          <b>DevCentral</b>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div
                    className={`bg-gray-50 p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      inViewGit ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      <b>Git</b> is a powerful version control system that helps
                      you manage changes in your projects, collaborate with
                      others, and and tracking work history. At{" "}
                      <a
                        href="https://devcentral.prabhavdev.me/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-semibold text-xs py-1 px-1 border border-gray-700 rounded-md transition duration-300 ease-in-out">
                          DevCentral
                        </button>
                      </a>{" "}
                      , we make learning Git simple with step-by-step guides to
                      key commands, <b>real-world examples</b>, and{" "}
                      <b>links to the best resources</b> to help you master it,
                      whether you're a beginner or refining your expertise.
                    </p>
                  </div>
                </div>

                {/* Sources */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-xl bg-white hover:shadow-md h-fit"
                  ref={refSources}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2">
                      <a
                        href="https://devcentral.prabhavdev.me/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-lg lg:text-xl font-semibold"
                      >
                        <button className="hover:bg-black bg-white text-black hover:text-white font-semibold text-sm xs:text-md py-2 px-2 border border-gray-300 rounded-lg transition duration-300 ease-in-out">
                          DevTools
                        </button>
                      </a>
                      <p className="text-md sm:text-lg hidden xs:block">
                        - git checkout{" "}
                        <a
                          href="https://devcentral.prabhavdev.me/"
                          target="_blank"
                          rel="noreferrer"
                          className="link_hover_effect"
                        >
                          <b>DevCentral</b>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div
                    className={`bg-gray-50 p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      inViewSources ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Discover tools that make a real difference in your
                      development process. Available at{" "}
                      <a
                        href="https://devcentral.prabhavdev.me/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-semibold text-xs py-1 px-1 border border-gray-700 rounded-md transition duration-300 ease-in-out">
                          DevCentral
                        </button>
                      </a>{" "}
                      , these tools are carefully chosen to help developers at
                      all levels. Whether you're <b>optimizing workflows</b>,{" "}
                      <b>building robust components</b>, or{" "}
                      <b>enhancing overall productivity</b>, these tools are
                      designed to address real-world challenges with a focus on
                      simplicity, efficiency, and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DevCentral;
